<template>
  <div>
    <div class="page-1">
      <print-layout :info="info" head_text="ต้นฉบับ"></print-layout>
    </div>
    <div class="page-2">
      <print-layout :info="info" head_text="สำเนา"></print-layout>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BCard,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import PrintLayout from "./component/PrintLayout.vue";

export default {
  components: {
    BButton,
    BTable,
    BCard,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    PrintLayout,
  },
  data() {
    return {
      pages: 0,
      clientHeight: null,
      parentHeight: 0,
      thai_format: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
      info: {},
      table_items: [],
      // fields: [
      //   "product_name",
      //   "serial_number",
      //   "rental_price",
      //   "price",
      //   "lease_price",
      // ],
      fields: [
        { key: "product_name", label: "#" },
        { key: "serial_number", label: "รายละเอียด" },
        { key: "rental_price", label: "ระยะเวลา (วัน)" },
        { key: "price", label: "ราคาต่อวัน" },
        { key: "lease_price", label: "คิดเป็นเงิน" },
      ],
    };
  },
  mounted() {
    // this.matchHeight();
  },
  created() {
    this.get_order();
  },

  methods: {
    matchHeight() {
      let height = this.$refs.printMe.clientHeight;
      this.pages = Math.ceil(height / 1300);
      console.log("height", height);
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    async get_order() {
      await this.$http({
        method: "GET",
        url: `/tax-invoice/print/${this.$route.params.id}`,
      })
        .then((x) => {
          console.log("return print ", x.data.data);
          this.info = x.data.data;
          // this.thai_format.start_date = new Date(
          //   a.start_date
          // ).toLocaleDateString("th-TH", {
          //   year: "numeric",
          //   month: "long",
          //   day: "numeric",
          // });
          // this.thai_format.end_date = new Date(a.end_date).toLocaleDateString(
          //   "th-TH",
          //   { year: "numeric", month: "long", day: "numeric" }
          // );
          // this.thai_format.start_time = moment(a.start_date).format("HH:mm");
          // this.thai_format.end_time = moment(a.end_date).format("HH:mm");

          // console.log("a", a);
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    thai_date(date) {
      if (date) {
        const month_list = [
          { name: "b_month", value: 1, title: "มกราคม", max_days: 31 },
          { name: "b_month", value: 2, title: "กุมภาพันธ์", max_days: 29 },
          { name: "b_month", value: 3, title: "มีนาคม", max_days: 31 },
          { name: "b_month", value: 4, title: "เมษายน", max_days: 30 },
          { name: "b_month", value: 5, title: "พฤษภาคม", max_days: 31 },
          { name: "b_month", value: 6, title: "มิถุนายน", max_days: 30 },
          { name: "b_month", value: 7, title: "กรกฎาคม", max_days: 31 },
          { name: "b_month", value: 8, title: "สิงหาคม", max_days: 31 },
          { name: "b_month", value: 9, title: "กันยายน", max_days: 30 },
          { name: "b_month", value: 10, title: "ตุลาคม", max_days: 31 },
          { name: "b_month", value: 11, title: "พฤศจิกายน", max_days: 31 },
          { name: "b_month", value: 12, title: "ธันวาคม", max_days: 31 },
        ];
        const d = moment(new Date(date));
        return (
          "วันที่",
          d.date() +
            " เดือน " +
            month_list.find((x) => x.value === d.month() + 1).title +
            " พ.ศ. " +
            (Number(d.year()) + Number(543))
        );
      }
    },
  },
};
</script>

<style>
@page {
  size: A4;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Sarabun", sans-serif !important;
  color: black !important;
  background-color: white !important;
}

.dark-layout {
  background-color: white !important;
}

.printable-page {
  background-color: white !important;
  padding: 1cm;
}

/* First page */
.page-1 {
  background-color: white !important;
  page-break-after: always; /* Force a page break after this content */
}

/* Second page */
.page-2 {
  background-color: white !important;
  page-break-before: always; /* Force a page break before this content */
}

@media print {
  body {
    background-color: white !important;
    font-family: "Sarabun", sans-serif !important;
  }
}

.centered-text {
  text-align: center;
}
.info-table td {
  padding-right: 20px;
}
.item-table {
  width: 100%;
  border-collapse: collapse; /* Optional for table cell spacing */
}

.item-table td:nth-child(1) {
  text-align: right;
  padding-right: 20px;
}

.item-table th,
.item-table td {
  padding: 2px;
}

.tax-table {
  width: 100%;
}

.tax-table th {
  background-color: #e9ecef;
}

.tax-table th {
  background-color: #e9ecef;
}

.text-18 {
  font-size: 18px;
}

.text-12 {
  font-size: 12px;
}

th {
  font-weight: lighter !important;
}

.my-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.my-table th,
.my-table td {
  padding-left: 20px !important;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.my-table th:first-child,
.my-table td:first-child {
  border-left: 1px solid black;
}
.my-table th:last-child,
.my-table td:last-child {
  border-right: 1px solid black;
}
</style>
