<template>
  <div>
    <!-- <h1>{{ info.cancel_status }}</h1> -->
    <div class="printable-page">
      <div class="row mb-5">
        <div class="col-8">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lenslineup22andbeyond.appspot.com/o/web_product_image%2F46da7b60-6850-11ee-8fe4-0934b15f26ef.png?alt=media&token=7b251f88-ac23-48f0-9928-136ba54c1fff"
            height="100"
            width="100"
            class="mb-2"
          />

          <div style="font-size: 14px">
            บริษัท คาเมร่า มัคคิอาโต จำกัด (สำนักงานใหญ่) <br />294/1 ห้อง 12 ซี
            ชั้น 12 อาคารเอเชีย
            <br />
            ถนนพญาไท แขวงถนนเพชรบุรี เขตราชเทวี กรุงเทพมหานคร 10400
            <br />
            เลขประจำตัวผู้เสียภาษี 0105561181776 <br />
            โทร. 02-611-0695 เบอร์มือถือ. 080-818-8872-74<br />
            www.lenslineup.com
          </div>
        </div>
        <div class="col-4 text-18 align-items-end">
          <div class="text-center">
            ใบกำกับภาษี / ใบเสร็จรับเงิน <br />{{ head_text }}
            (เอกสารออกเป็นชุด)
          </div>

          <hr style="border-color: #e9ecef" />
          <!-- margin-left: 50% -->
          <table class="info-table" style="font-size: 14px">
            <tbody>
              <tr>
                <td>เลขที่</td>
                <td>{{ info.tax_invoice_no }}</td>
              </tr>
              <tr>
                <td>วันที่</td>
                <td>{{ formattedDate }}</td>
              </tr>
              <tr>
                <td>เอกสารอ้างอิง</td>
                <td>{{ info.order_id }}</td>
              </tr>
              <!-- <tr>
                <td>ผู้ขาย</td>
                <td>{{ info.seller_name }}</td>
              </tr> -->
              <!-- <tr>
                <td>ผู้ติดต่อ</td>
                <td>{{ info.contact_name }}</td>
              </tr>
              <tr>
                <td>เบอร์โทร</td>
                <td>{{ info.contact_phone }}</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b>ลูกค้า</b> <br />
          {{ info.customer_name }} <br />
          {{ info.billing_address }} <br />
          เลขประจำตัวผู้เสียภาษี {{ info.card_id }}
        </div>
      </div>
      <br />
      <br />
      <div class="row pb-2">
        <div class="col">
          <table class="tax-table" style="font-size: 14px">
            <thead>
              <tr>
                <th style="padding: 1rem">#</th>
                <th>รายละเอียด</th>
                <th>{{ info.quantity_text }}</th>
                <!-- ระยะเวลา (วัน)  -->
                <th>{{ info.price_text }}</th>
                <!-- ราคาต่อวัน   -->
                <th>คิดเป็นเงิน</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in info.items"
                :key="index"
                style="border-bottom: 1px solid #e9ecef"
              >
                <td style="padding: 0.75rem">{{ index + 1 }}</td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.rental_price | number }}</td>
                <td>
                  {{ (item.rental_price * item.duration) | number }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col">
          <table class="item-table">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="10">
                  <div style="padding-top: 5px">
                    ( {{ info.thaiBahtText }} )
                  </div>
                </td>
                <td class="text-right"></td>
              </tr>
              <tr>
                <td>รวมเป็นเงิน</td>
                <td class="text-right">{{ info.total_price | number }} บาท</td>
              </tr>
              <tr v-if="info.discount">
                <td>ส่วนลด</td>
                <td class="text-right">{{ info.discount | number }} บาท</td>
              </tr>
              <tr>
                <td>จำนวนเงินหลังหักส่วนลด</td>
                <td class="text-right">
                  {{ info.total_net_price | number }} บาท
                </td>
              </tr>
              <tr>
                <td>ภาษีมูลค่าเพิ่ม 7%</td>
                <td class="text-right">{{ info.vat | number }} บาท</td>
              </tr>
              <tr>
                <td>ราคาไม่รวมภาษีมูลค่าเพิ่ม</td>
                <td class="text-right">
                  {{ info.price_before_vat | number }} บาท
                </td>
              </tr>
              <tr>
                <td>
                  จำนวนเงินรวมทั้งสิ้น
                  <div style="font-size: 10px">(ราคารวมภาษีมูลค่าเพิ่ม)</div>
                </td>
                <td class="text-right">
                  {{ info.price_after_vat | number }} บาท
                </td>
              </tr>
              <tr v-if="show_with_holding === true">
                <td class="text-right"></td>
                <td>
                  <hr style="border-color: #e9ecef" />
                </td>
              </tr>
              <tr v-if="show_with_holding === true">
                <td><br />หักภาษี ณ ที่จ่าย {{ info.selectedWithHolding }}%</td>
                <td class="text-right">
                  <br />{{ info.with_holding_price }} บาท
                </td>
              </tr>
              <tr v-if="show_with_holding === true">
                <td>ยอดชำระ</td>
                <td class="text-right">
                  {{ info.after_with_holding_price | number }} บาท
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table style="width: 100%">
            <tbody>
              <tr>
                <td class="centered-text">
                  <br /><br /><br />
                  ____________________________________________________________
                  <br />
                  <br />
                  (__________________________________________________) <br />
                  ผู้ชำระเงิน
                </td>

                <td class="centered-text">
                  ในนามบริษัท คาเมร่า มัคคิอาโต จำกัด <br /><br /><br />
                  ____________________________________________________________
                  <br />
                  <br />
                  (__________________________________________________) <br />
                  ผู้รับเงิน
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    formattedDate() {
      const originalDate = new Date(this.info.date);
      const day = originalDate.getDate();
      const month = originalDate.getMonth() + 1; // Months are zero-based, so add 1
      const year = originalDate.getFullYear();

      return `${day}/${month}/${year}`;
    },
    show_with_holding() {
      console.log(
        "this.info.selectedWithHolding",
        this.info.selectedWithHolding
      );
      if (this.info.selectedWithHolding > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: ["info", "head_text"],
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
<style>
.printable-page {
  background-color: white !important;
  padding: 1cm;
}
</style>
